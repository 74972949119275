@font-face {
  font-family: Fugaz One;
  src: url("FugazOneRegular.ac5adbe2.ttf");
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  grid-template-columns: 12fr 88fr;
  font-family: Fugaz One;
  display: grid;
  position: relative;
}

html {
  font-size: 62.5%;
}

main {
  background-image: linear-gradient(#ffffffb3, #ffffffb3), url("round.logo.b776dbc6.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 75%;
}

.main-container {
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 10rem;
  padding: 0 2.4rem;
  display: flex;
}

.changeable-container {
  background-color: #fff;
  border-radius: .8rem;
  max-width: 60rem;
  box-shadow: 0 1.2rem 2.4rem #0000004d;
}

.eq-title-container {
  background-color: var(--main-bg-color);
  color: #eee;
  text-align: center;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
  padding: .4rem 2.4rem;
  font-size: 1.4rem;
}

.grid-2-cols {
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem 3.2rem;
}

.eq-container {
  place-items: center;
  padding: 2.4rem;
  display: grid;
}

.eq-input {
  color: #333;
  text-align: center;
  border: .1rem solid #ddd;
  border-radius: .4rem;
  outline: none;
  max-width: 10.4rem;
  margin-right: .4rem;
  padding: .8rem 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
  box-shadow: .2rem .2rem .4rem #0000001a;
}

.unit-selector {
  cursor: pointer;
  color: #333;
  border: .1rem solid #ddd;
  border-radius: .4rem;
  outline: none;
  width: 10.4rem;
  min-width: 8.6rem;
  height: 4.5rem;
  padding: .4rem .6rem;
  font-family: inherit;
  font-size: 1.4rem;
  box-shadow: .2rem .2rem .4rem #0000001a;
}

option {
  text-align: center;
}

.eq-component {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  display: flex;
}

.eq-component--center {
  grid-column: 1 / span 2;
  justify-self: center;
}

.eq-label {
  margin-bottom: .8rem;
  font-size: 1.8rem;
  display: inline-block;
}

.btn-container {
  text-align: center;
  grid-column: 1 / span 2;
  justify-self: center;
}

.gender-container {
  border-radius: .8rem;
  justify-content: space-between;
  width: 17.8rem;
  display: flex;
}

.btn--male {
  text-align: center;
  border: .1rem solid #ddd;
  border-radius: .4rem 0 0 .4rem;
  width: 50%;
  padding: .8rem;
  box-shadow: 0 .2rem .2rem #0000001a;
}

.btn--female {
  text-align: center;
  border: .1rem solid #ddd;
  border-radius: 0 .4rem .4rem 0;
  width: 50%;
  padding: .8rem;
  box-shadow: 0 .2rem .2rem #0000001a;
}

.label--gender {
  text-align: center;
  display: block;
}

.btn--gender {
  background-color: inherit;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: 1.8rem;
  transition: all .3s;
}

.gender-clicked, .btn--gender:hover {
  background-color: var(--main-bg-color);
  color: var(--off-white);
}

.btn-calculate {
  background-color: var(--main-bg-color);
  color: var(--off-white);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: .4rem;
  margin-bottom: 1.2rem;
  padding: .6rem 1.2rem;
  font-family: inherit;
  font-size: 2rem;
  transition: all .3s;
  box-shadow: .2rem .2rem .2rem #0003;
}

.btn-calculate:hover {
  background-color: #1140698e;
  transform: scale(1.1);
}

.btn-calculate:active {
  transform: scale(.9);
}

.answer-container {
  text-align: center;
  max-width: 50rem;
  margin-top: 2.4rem;
  padding: 1.2rem 2.4rem;
  font-size: 2.4rem;
}

.future {
  opacity: .5;
}

.tooltip {
  position: relative;
  top: 0;
}

.tooltip-icon {
  cursor: pointer;
  height: 1.6rem;
  position: absolute;
}

.interpretation-text {
  font-size: 1.8rem;
}

@media (width <= 106.25em) {
  .nav-item h2, .menu-dropdown {
    font-size: 1.6rem;
  }
}

@media (width <= 81.25em) {
  html {
    font-size: 60%;
  }

  .btn--eq {
    font-size: 1.54rem;
  }

  body {
    grid-template-columns: 15fr 85fr;
  }
}

@media (width <= 71.9em) {
  .btn--eq {
    font-size: 1.54rem;
  }
}

@media (width <= 56.25em) {
  body {
    grid-template-columns: 20fr 80fr;
  }
}

@media (width <= 41.9em) {
  html {
    font-size: 62.5%;
  }

  body {
    grid-template-columns: 1fr 1fr;
  }

  .mobile-selected {
    grid-template-columns: 1fr;
  }

  .mobile-selected main {
    grid-column: span 2;
  }

  .hidden-mobile {
    display: none;
  }

  .navbar {
    justify-self: start;
  }

  main {
    background-position: 50% 10rem;
    background-size: 100%;
  }

  .side-icon {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .main-container {
    margin-top: 5rem;
  }
}

@media (width <= 36em) {
  .eq-container {
    flex-direction: column;
    display: flex;
  }
}

/*# sourceMappingURL=index.f21b48be.css.map */

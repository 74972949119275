/* SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */
@font-face {
  font-family: 'Fugaz One';
  src: url('../fonts/FugazOneRegular.ttf');
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Fugaz One';
  display: grid;
  grid-template-columns: 12fr 88fr;
  position: relative;
}

html {
  font-size: 62.5%;
}

main {
  background-image: linear-gradient(
      rgb(255, 255, 255, 0.7),
      rgb(255, 255, 255, 0.7)
    ),
    url('../images/round.logo.png');
  /* background: url('../images/logo-no-background.png'); */
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: top center;
}

.main-container {
  height: 100vh;
  /* margin: 20rem; */
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem;
}

.changeable-container {
  max-width: 60rem;
  box-shadow: 0 1.2rem 2.4rem rgb(0, 0, 0, 0.3);
  border-radius: 0.8rem;
  background-color: #fff;
}

.eq-title-container {
  background-color: var(--main-bg-color);
  color: #eee;
  font-size: 1.4rem;
  padding: 0.4rem 2.4rem;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  text-align: center;
}

.grid-2-cols {
  grid-template-columns: 1fr 1fr;
  row-gap: 2.4rem;
  column-gap: 3.2rem;
}

.eq-container {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 2.4rem;
}

.eq-input {
  font-size: 1.8rem;
  max-width: 10.4rem;
  outline: none;
  border: #ddd solid 0.1rem;
  border-radius: 0.4rem;
  padding: 0.8rem 1.2rem;
  color: #333;
  margin-right: 0.4rem;
  box-shadow: 0.2rem 0.2rem 0.4rem rgb(0, 0, 0, 0.1);
  font-family: inherit;
  text-align: center;
}

.unit-selector {
  font-size: 1.4rem;

  outline: none;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  color: #333;
  border-radius: 0.4rem;
  border: #ddd solid 0.1rem;
  width: 10.4rem;
  height: 4.5rem;
  /* height: 4rem; */
  box-shadow: 0.2rem 0.2rem 0.4rem rgb(0, 0, 0, 0.1);
  font-family: inherit;
  min-width: 8.6rem;
}

option {
  text-align: center;
}

.eq-component {
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eq-component--center {
  grid-column: 1 / span 2;
  justify-self: center;
}

.eq-label {
  font-size: 1.8rem;
  display: inline-block;
  margin-bottom: 0.8rem;
}

.btn-container {
  text-align: center;
  grid-column: 1 / span 2;
  justify-self: center;
}

.gender-container {
  width: 17.8rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.8rem;
}

.btn--male {
  width: 50%;
  text-align: center;

  border: #ddd solid 0.1rem;

  border-radius: 0.4rem 0 0 0.4rem;
  padding: 0.8rem;
  box-shadow: 0 0.2rem 0.2rem rgb(0, 0, 0, 0.1);
}

.btn--female {
  width: 50%;
  text-align: center;

  border: #ddd solid 0.1rem;
  border-radius: 0 0.4rem 0.4rem 0;
  padding: 0.8rem;
  box-shadow: 0 0.2rem 0.2rem rgb(0, 0, 0, 0.1);
}

.label--gender {
  display: block;
  text-align: center;
}

.btn--gender {
  background-color: inherit;
  font-size: 1.8rem;
  font-family: inherit;
  transition: all 0.3s;
  cursor: pointer;
  color: inherit;
}

.gender-clicked,
.btn--gender:hover {
  background-color: var(--main-bg-color);
  color: var(--off-white);
}

.btn-calculate {
  font-size: 2rem;
  background-color: var(--main-bg-color);
  border: none;
  color: var(--off-white);
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  text-transform: uppercase;
  font-family: inherit;
  margin-bottom: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0.2rem 0.2rem 0.2rem rgb(0, 0, 0, 0.2);
}

.btn-calculate:hover {
  background-color: rgba(17, 64, 105, 0.555);
  transform: scale(1.1);
}

.btn-calculate:active {
  transform: scale(0.9);
}

.answer-container {
  margin-top: 2.4rem;
  font-size: 2.4rem;
  padding: 1.2rem 2.4rem;
  max-width: 50rem;
  /* height: 20rem; */
  text-align: center;
}

.future {
  opacity: 50%;
}

.tooltip {
  position: relative;
  top: 0;
}

.tooltip-icon {
  height: 1.6rem;
  position: absolute;
  cursor: pointer;
}

.interpretation-text {
  font-size: 1.8rem;
}

/*1700px*/
@media (max-width: 106.25em) {
  .nav-item h2,
  .menu-dropdown {
    font-size: 1.6rem;
  }
}

/*1300px*/
@media (max-width: 81.25em) {
  html {
    font-size: 60%;
  }

  .btn--eq {
    font-size: 1.54rem;
  }

  body {
    grid-template-columns: 15fr 85fr;
  }
}

/*1150*/

@media (max-width: 71.9em) {
  .btn--eq {
    font-size: 1.54rem;
  }
}

/*900*/
@media (max-width: 56.25em) {
  body {
    grid-template-columns: 20fr 80fr;
  }
}

/*760*/
@media (max-width: 47.5em) {
}

/*670*/
@media (max-width: 41.9em) {
  html {
    font-size: 62.5%;
  }

  body {
    grid-template-columns: 1fr 1fr;
    /* need to change grid-template columns to 1fr when a selection is made
    and make main span 2 columns */
  }

  .mobile-selected {
    grid-template-columns: 1fr;
  }

  .mobile-selected main {
    grid-column: span 2;
  }

  .hidden-mobile {
    display: none;
  }

  .navbar {
    justify-self: start;
  }

  main {
    background-size: 100%;
    background-position: top 10rem center;
  }

  .side-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-container {
    margin-top: 5rem;
  }
}

/*575*/
@media (max-width: 36em) {
  html {
    /* font-size: 50%; */
  }

  .eq-container {
    display: flex;
    flex-direction: column;
  }
}

/*440*/
@media (max-width: 27.5em) {
}

/* SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */
